// 
// custom.scss
//

// body
body {
    background: #E5E5E5 !important;
}

.hidden {
    display: none;
}

.mr-17-px {
    margin-right: 17px !important;
}

// start : selected email
.selected-email-subject {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #495057;
}

.ellipse {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #A6B0CF;
    background: #FFF;
    display: inline-block;
    align-items: center;
}

.selected-email-rect-btn {
    width: 147px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ECECF5;
    background: #FFF;
    display: inline-block;
    align-items: center;
}

// end : selected email

.view-profile {
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    border-radius: 3px;
    color: #2F80ED;
    background-color: #fff;
    font-size: 11px !important;
}

// start : email - inbox
.main-content .email-inbox {
    background-color: #FFF;
    margin: 0;
    padding: 60 0 0 0;
    // style={{paddingTop: 70, backgroundColor: '#FFF'}}
}
// .container-fluid .email-inbox {
//     padding: 10 0 10 0;
//     height: 100%;
//     background-color: #FFF;
//     // style={{padding: 0, height: '100%', backgroundColor: '#FFF'}}
// }

.new-email-container {
    position: absolute;
    right: 50px;
    bottom: 0;
    width: 696px;
    height: 664px;
    background-color: #FFF;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 6px 20px rgba(63, 70, 103, 0.08);
}

.new-email .minimized {
    height: 50px !important;
}
// style={{position: 'absolute', right: 50, bottom: 0, width: 696, height: newEmailMinimized == true ? 50 : '50%', backgroundColor: '#FFF', borderRadius: '4px 4px 0px 0px', boxShadow: '0px 6px 20px rgba(63, 70, 103, 0.08)'}}

.new-email-btn {
    // style={{background: '', borderRadius: 3, color: '#FFF', border: 0, width: 117, height: 29, alignItems: 'center'}}
    background: #2F80ED;
    border-radius: 3px;
    color: #FFF;
    border: 0;
    width: 117px;
    height: 29px;
    text-align: center;
}

.email-inbox-header {
    background-color: #FFF;
    margin: 0;
    padding: 0;
    width: 100%;
}

.email-subject-h5 {
    // padding-left: 10px;
    display: inline-block;
}

.new-email-top {
    display: flex;
    width: 100%;
}

.net-left {
    flex: 0 0 50%;
}

.net-right {
    flex: 0 0 50%;
    text-align: right !important;
}

.nef-tabs-container {
    display: flex;
    margin: 0;
    padding: 0 20 0 20;
    align-items: center;
    width: 696px;
    color: #8890AB;
}

.new-email-tab {
    margin: 16 0 16 0 !important;
    padding: 0 !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #A6B0CF;
}

.nef-tabs-container .active {
    border-bottom: 1px solid #2F80ED !important;
    color: #2F80ED !important;
}

.new-email-tab .active {
    border-bottom: 1px solid #2F80ED !important;
    color: #2F80ED !important;
}

// .nef-container hr{
//     border: 1.25px solid #ECECF5;
// }

.nef-input {
    width: 100%;
    display: flex;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.nef-label {
    width: 68px;
    height: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8890AB;
    flex: 0 0 30%;
    margin-left: 5px;
}

.nef-select {
    border: 0 !important;
    flex: 0 0 70%;
    height: 20px;
    // 
}

.nef-footer {
    display: flex;
    width: 100%;
    margin: 22px 5px 22px 5px;
}

.nef-footer .arrow {
    height: 4px;
    width: 8px;
}

.nef-footer-btn {
    // width: 9.38px;
    height: 15px;
    margin-right: 20px;
}

.nef-send-btn {
    background: #2F80ED;
    border-radius: 3px;
    border: 1px solid #FFF
}

.nef-footer-btn.arrow {
    height: 3px !important;
    margin-top: 10px;
    margin-left: -13px !important;
}

[class^='select2'] {
    border: none !important;
}
// end : email - inbox

.vertical-menu {
    background-color: #fff !important;
}

.navbar-brand-box {
    background-color: #fff !important;
}

.waves-effect span, .waves-effect i {
    color: #A6B0CF !important;
}

.mm-active span {
    color: #6A7187 !important;
}

body[data-sidebar="dark"] .mm-active .active {
    color: #6A7187 !important;
}

body[data-sidebar="dark"] .mm-active > i {
    color: #6A7187 !important;
}

.search-not-found {
    text-align: center
}