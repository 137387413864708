.star {
    fill: #FFFFFF;
    stroke: #A6B0CF;
    display: inline-block;
}

.star-selected {
    fill: #F2C94C;
    stroke: #F2C94C;
    display: inline-block;
}