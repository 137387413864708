.TextField{
    /* margin-top: 20px; */
    display: flex;
}

.Change{
    /* padding-right: 0.25em; */
    padding-right: 0px;
}
  
input{
    border: none;
    outline: none;
    padding: 0px;
}

/* .break-word{
    overflow-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 5px;
    padding-right: 2px;

    .btn{
        padding: 5px 5px;
        margin: 0!important;
    }
} */